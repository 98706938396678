var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"550px"},model:{value:(_vm.shower),callback:function ($$v) {_vm.shower=$$v},expression:"shower"}},[_c('v-card',[_c('v-card-text',{staticStyle:{"height":"auto","overflow-y":"hidden"}},[(_vm.resolutionScreen < 500)?_c('v-layout',{staticClass:"mt-3 mb-n6"},[_c('v-flex',{staticClass:"text-right",attrs:{"lg12":""}},[_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.$emit('closedialog')}}},[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('div',{staticClass:"ma-4 pa-0 text-center"},[_c('img',{attrs:{"src":require("@/assets/img/5910366.png"),"width":_vm.resolutionScreen >= 400 ? '120px' : '100px',"height":_vm.resolutionScreen >= 400 ? '120px' : '100px',"alt":"One Box"}})]),(_vm.taxinvoicedata.sender_id === '0107537000521')?_c('div',{staticClass:"text-center"},[_c('br'),_c('span',{style:(_vm.resolutionScreen < 500 ? 'font-size: 18px;' : 'font-size: 20px;')},[_vm._v(" ท่านสามารถเปิดดูรายละเอียดโดยใส่รหัสเลขท้ายรหัสบัตรประชาชนของท่าน 6 หลัก ")])]):(
          _vm.taxinvoicedata.sender_id === '0105559156778' ||
            _vm.taxinvoicedata.sender_id === '1798142971048'
        )?_c('div',{staticClass:"mx-3",staticStyle:{"font-size":"20px","line-height":"26px"},style:(_vm.resolutionScreen < 500
            ? 'height:400px; font-size: 14px;'
            : 'font-size: 20px;')},[_c('div',{staticClass:"mb-3"},[_c('b',[_vm._v(" เรียน คุณลูกค้า ")])]),_c('span',{staticStyle:{"padding-left":"4em"},style:(_vm.resolutionScreen < 500
            ? 'font-size: 13px;'
            : 'font-size: 18px;')},[_vm._v(" บริษัท เฟิร์สคลาส อินโนเวชั่น จำกัด ขอเรียนแจ้งยกเลิกเอกสารใบกำกับภาษีอิเล็กทรอนิกส์ฉบับก่อนหน้า ที่ท่านได้รับผ่านการยื่นคำขอผ่านทาง Taxbox เนื่องจากความผิดพลาดในการทดสอบระบบการออกใบกำกับภาษี ในช่วงวันที่ 1 มกราคม 2567 ถึง 18 มกราคม 2567 และจะดำเนินการปรับปรุงระบบเพื่อส่งใบกำกับภาษีเต็มรูปแบบใบใหม่ให้ท่านตามช่องทางที่ได้มีการแจ้งไว้กับทางบริษัท ให้แล้วเสร็จในเดือนกุมภาพันธ์ จึงขออภัยในความไม่สะดวก มา ณ ที่นี้ ")]),_c('br'),_c('div',{staticClass:"mt-3"},[_c('b',[_vm._v(" ขอแสดงความนับถือ ")]),_c('br'),_c('b',[_vm._v(" บริษัท เฟิร์สคลาส อินโนเวชั่น จำกัด ")])])]):_vm._e(),_c('br'),_c('br'),_c('div',{staticClass:"text-center"},[(_vm.resolutionScreen >= 500)?_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.$emit('closedialog')}}},[_vm._v(" "+_vm._s(_vm.$t("changeName.close")))]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }