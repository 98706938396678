<template>
  <v-dialog v-model="shower" scrollable persistent max-width="550px">
    <v-card>
      <v-card-text style="height: auto; overflow-y:hidden;">
        <v-layout class="mt-3 mb-n6" v-if="resolutionScreen < 500">
          <v-flex lg12 class="text-right">
            <v-icon @click="$emit('closedialog')" color="red">mdi-close</v-icon>
          </v-flex>
        </v-layout>
        <div class="ma-4 pa-0 text-center">
          <img
            src="@/assets/img/5910366.png"
            :width="resolutionScreen >= 400 ? '120px' : '100px'"
            :height="resolutionScreen >= 400 ? '120px' : '100px'"
            alt="One Box"
          />
        </div>
        <div
          class="text-center"
          v-if="taxinvoicedata.sender_id === '0107537000521'"
        >
          <!-- taxinvoicedata.sender_id === '1798142971048' -->
          <!-- || taxinvoicedata.sender_id === '6150328111820' -->
          <!-- <h2>{{ taxinvoicedata.sender_name }}</h2> -->
          <br />
          <!-- <span v-if="$t('default') === 'th'" style="font-size: 20px;"> -->
          <span
            :style="
              resolutionScreen < 500 ? 'font-size: 18px;' : 'font-size: 20px;'
            "
          >
            ท่านสามารถเปิดดูรายละเอียดโดยใส่รหัสเลขท้ายรหัสบัตรประชาชนของท่าน 6
            หลัก
          </span>
          <!-- <span v-else style="font-size: 20px;">
            Please enter the last 6 digits of your ID card number to unlock your
            document.
          </span> -->
        </div>
        <div
          v-else-if="
            taxinvoicedata.sender_id === '0105559156778' ||
              taxinvoicedata.sender_id === '1798142971048'
          "
          style="font-size: 20px; line-height:26px;"
          :style="
            resolutionScreen < 500
              ? 'height:400px; font-size: 14px;'
              : 'font-size: 20px;'
          "
          class="mx-3"
        >
        <div class="mb-3">
          <b>
            เรียน คุณลูกค้า </b
          >
        </div>
          <span
          style="padding-left: 4em;"
          :style="
            resolutionScreen < 500
              ? 'font-size: 13px;'
              : 'font-size: 18px;'
          "
          class=""
          >
            &emsp;บริษัท เฟิร์สคลาส อินโนเวชั่น จำกัด
            ขอเรียนแจ้งยกเลิกเอกสารใบกำกับภาษีอิเล็กทรอนิกส์ฉบับก่อนหน้า
            ที่ท่านได้รับผ่านการยื่นคำขอผ่านทาง Taxbox
            เนื่องจากความผิดพลาดในการทดสอบระบบการออกใบกำกับภาษี ในช่วงวันที่ 1
            มกราคม 2567 ถึง 18 มกราคม 2567
            และจะดำเนินการปรับปรุงระบบเพื่อส่งใบกำกับภาษีเต็มรูปแบบใบใหม่ให้ท่านตามช่องทางที่ได้มีการแจ้งไว้กับทางบริษัท
            ให้แล้วเสร็จในเดือนกุมภาพันธ์ จึงขออภัยในความไม่สะดวก มา ณ ที่นี้
          </span>
          <br />
          <div class="mt-3">
            <b>
              ขอแสดงความนับถือ </b
            ><br />
            <b>
              บริษัท เฟิร์สคลาส อินโนเวชั่น จำกัด
            </b>
          </div>
        </div>
        <br />
        <br />
        <div class="text-center">
          <v-btn
            v-if="resolutionScreen >= 500"
            color="red"
            outlined
            @click="$emit('closedialog')"
          >
            {{ $t("changeName.close") }}</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VueCookies from "vue-cookies";
import { mapState, mapGetters } from "vuex";
export default {
  props: ["show", "taxinvoicedata"],
  data: function() {
    return {
      checkshow: false,
      dialog: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
        }
        return this.show;
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  methods: {
    // checkdialog() {
    //    VueCookies.set('checkalertcondition', false);
    //    this.$emit("closedialog")
    // },
  },
};
</script>

<style></style>
